import React from 'react'
import Helmet from 'react-helmet'
import { Layout } from '../layouts/Layout'
import { SEO } from '../components/shared'

const MobileCoverageMap = () => (
  <Layout>
    <SEO
      title="Pronto Mobile Coverage Map"
      description="Check network mobile coverage"
    />
    <Helmet>
      <script
        type="text/javascript"
        src="https://opt-au.spatialbuzz.net/cust/6E867DF0/public/init/bootstrap-coverage-acurus-6E867DF0-9B7173DC-iframe-init-outer.js"
      ></script>
      <script
        type="text/javascript"
        src="https://opt-au.spatialbuzz.net/cust/6E867DF0/public/dist/boot-iframe.js"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.onload = function() {
            window.initSBIframe('sb_coverage');
          };`,
        }}
      />
    </Helmet>

    <main id="mobile-coverage-map" className="single">
      <div className="container">
        <h1 className="page-title text-center">Mobile Coverage Checker</h1>
        <p className="text-center">
          To find out if your area has mobile coverage, simply enter your
          street, suburb or postcode and then select the type of device you are
          using.
        </p>
      </div>
      <div className="container">
        <div
          id="sb_coverage"
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: `<p>Unable to load map</p>
            <button class="btn btn-lg btn-accord" onclick="window.initSBIframe('sb_coverage')">Try Again</button>`,
          }}
        />
      </div>
    </main>
  </Layout>
)
export default MobileCoverageMap
